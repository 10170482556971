import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Loading from "../Loading/Loading";
import NewsItem from "../NewsItem/NewsItem";
import NullImage from "../../components/Images/nullImage.png";
import { useDispatch, useSelector } from "react-redux";
import { searchArticle } from "../../store/action";
import { useParams } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { header, noFound, searching } from "../../utils/util";
import { Container, Header, card } from "./index";

function Search() {
  const [searchQuery, setSearchQuery] = useState("");
  const [totalArticles, setTotalArticle] = useState(0);
  const [selectedSource, setSelectedSource] = useState("newsapi");
  const { articles, loading } = useSelector((state) => state.search);
  const { query } = useParams();

  const dispatch = useDispatch();

  // Set searchQuery on initial mount or when query changes
  useEffect(() => {
    setSearchQuery(query);
  }, [query]);

  // Trigger the search when the query or selected source changes
  useEffect(() => {
    if (searchQuery) {
      dispatch(searchArticle(searchQuery, selectedSource));
    }
  }, [searchQuery, selectedSource, dispatch]);

  // Update total articles when articles state changes
  useEffect(() => {
    setTotalArticle(articles.totalArticles);
  }, [articles]);
  // useEffect(() => {
  //   console.log("🚀 ~ file: Search.js:19 ~ query:", query);
  //   console.log("🚀 ~ file: Search.js:15 ~ searchQuery:", searchQuery);
  //   dispatch(searchArticle(query, selectedSource));
  // }, [query, searchQuery, selectedSource, dispatch]);

  // useEffect(() => {
  //   setSearchQuery(query);
  //   setTotalArticle(articles.totalArticles);
  // }, [query, articles]);

  // const normalizedArticles = articles
  //   ? normalizeNewsData(selectedSource, articles.articles)
  //   : [];

  // console.log(
  //   "🚀 ~ file: Search.js:43 ~ normalizedArticles:",
  //   normalizedArticles
  // );
  const capitaLize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  document.title =
    totalArticles === 0
      ? noFound
      : loading
      ? searching
      : `${capitaLize(searchQuery)} - News`;

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header>
            {totalArticles === 0 ? noFound : header(capitaLize(searchQuery))}
          </Header>
          <Container>
            <Row className="mb-4">
              <Col sm={4}>
                <Form.Group controlId="sourceSelect">
                  <Form.Label>Select Source</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedSource}
                    onChange={(e) => setSelectedSource(e.target.value)}
                  >
                    <option value="newsapi">NewsAPI</option>
                    <option value="guardianapis">The Guardian</option>
                    <option value="nyt">The New York Times</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              {articles?.map((element) => {
                return (
                  <Col sm={12} md={6} lg={4} xl={3} style={card} key={uuidv4()}>
                    <NewsItem
                      title={element.title}
                      description={element.description}
                      published={element.publishedAt}
                      channel={element.source.name}
                      alt="News image"
                      publishedAt={element.publishedAt}
                      imageUrl={
                        element.imageUrl === null ? NullImage : element.imageUrl
                      }
                      urlNews={element.url}
                    />
                  </Col>
                );
              })}
            </Row>
          </Container>
        </>
      )}
    </>
  );
}

export default Search;
