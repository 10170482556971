const GUARDIAN_API_KEY = "7f7fb9f1-d035-462d-8e56-de7f582ebeef";
const NEWS_API_KEY = "d4e503f4bdf7427b958c9fcf4c03c0a8";
const NYT_API_KEY = "BlTx0DuZJF2MB4MAvWU7FeZ7QfgRayWY";

// const API_DOMAINS = {
//   newsapi: "https://newsapi.org/v2/top-headlines",
//   guardianapis: "https://content.guardianapis.com/search",
// };

const API_SEARCH_DOMAIN = {
  newsapi: "https://newsapi.org/v2/everything",
  guardianapis: "https://content.guardianapis.com/search",
};

const API_DOMAINS = {
  newsapi: {
    headlines: "https://newsapi.org/v2/top-headlines",
    search: "https://newsapi.org/v2/everything",
  },
  guardianapis: "https://content.guardianapis.com/search",
  nyt: {
    headlines: "https://api.nytimes.com/svc/topstories/v2",
    search: "https://api.nytimes.com/svc/search/v2/articlesearch.json",
  },
};

const buildEndpointPath = (params) => {
  const {
    source,
    searchQuery,
    country = "us",
    category = "general",
    date,
    query,
    fromDate,
    toDate,
    orderBy = "newest",
    sortBy = "publishedAt",
    isSearch = false,
    pageSize = 10,
  } = params;

  let url = "";

  switch (source) {
    case "guardianapis":
      url = `${API_DOMAINS[source]}?api-key=${GUARDIAN_API_KEY}`;
      if (query || searchQuery) url += `&q=${query || searchQuery}`;
      if (isSearch) {
        url += `&page-size-${pageSize}`;
      } else {
        if (category) url += `&section=${category}`;
        if (fromDate) url += `&from-date=${fromDate}`;
        if (toDate) url += `&to-date=${toDate}`;
      }
      url += `&order-by=${orderBy}&show-fields=all`;
      break;

    case "newsapi":
      if (isSearch) {
        url = `${API_DOMAINS[source].search}?apiKey=${NEWS_API_KEY}`;
        if (searchQuery) url += `&q=${searchQuery}`;
        if (fromDate) url += `&from=${fromDate}`;
        url += `&sortBy=${sortBy}`;
      } else {
        url = `${API_DOMAINS[source].headlines}?apiKey=${NEWS_API_KEY}`;
        if (country) url += `&country=${country}`;
        if (category) url += `&category=${category}`;
        if (date) url += `&from=${date}`;
      }
      break;

    case "nyt":
      if (isSearch) {
        url = `${API_DOMAINS[source].search}?api-key=${NYT_API_KEY}`;
        if (searchQuery) url += `&q=${searchQuery}`;
        if (fromDate) url += `&begin_date=${fromDate.replace(/-/g, "")}`;
        if (toDate) url += `&end_date=${toDate.replace(/-/g, "")}`;
        if (orderBy) url += `&sort=${orderBy}`;
      } else {
        url = `${API_DOMAINS[source].headlines}/${
          category ? category : "home"
        }.json?api-key=${NYT_API_KEY}`;
      }
      break;

    default:
      throw new Error("Unsupported API source");
  }

  return url;
};

export default buildEndpointPath;
// export const endpointPath = (country, category, source, date) => {
//   let url = `${API_DOMAINS}?apiKey=${NEWS_API_KEY}`;

//   if (source) {
//     url += `&sources=${source}`;
//   } else {
//     if (country) {
//       url += `&country=${country}`;
//     }
//     if (category) {
//       url += `&category=${category}`;
//     }
//   }

//   if (date) {
//     url += `&from=${date}`;
//   }

//   return url;
// };

// export const guardianEndpointPath = (
//   section,
//   query,
//   fromDate,
//   toDate,
//   orderBy = "newest"
// ) => {
//   let url = `${GUARDIAN_API_DOMAIN}?api-key=${GUARDIAN_API_KEY}`;

//   if (section) {
//     url += `&section=${section}`;
//   }

//   if (query) {
//     url += `&q=${query}`;
//   }

//   if (fromDate) {
//     url += `&from-date=${fromDate}`;
//   }

//   if (toDate) {
//     url += `&to-date=${toDate}`;
//   }

//   url += `&order-by=${orderBy}&show-fields=all`;

//   console.log("🚀 ~ Guardian API URL:", url);
//   return url;
// };
export const endpointSearch = (
  searchQuery,
  fromDate,
  sortBy = "publishedAt"
) => {
  return `${API_SEARCH_DOMAIN}?q=${searchQuery}&from=${fromDate}&sortBy=${sortBy}&apiKey=${NEWS_API_KEY}`;
};
